import { defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';

import { DEFAULT_NATION } from '@/constants/common.const';
import { MINIMUM_AGES_ALLOWED_TO_REGISTER_IN_MILISECONDS } from '@/constants/date-time.const';
import {
  PUBLIC_STUDIO_REGISTER_PAGE_URL,
  PUBLIC_STUDIO_SERVICE_UNAVAILABLE_PAGE_NAME
} from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { ServiceUnavailable } from '@/enums/service-unavailable.enum';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import { redirectTo } from '@/utils/common.util';
import {
  goToPolicyStudioBirthPage,
  goToStudioOnStoveAccountVerificationPage
} from '@/utils/user.utils';

export default defineNuxtRouteMiddleware(async (_to: RouteLocationNormalized) => {
  const userStore = useUserStore();
  const { userInfo } = storeToRefs(userStore);
  const { gdsInfo } = storeToRefs(useAppStore());
  const nuxtApp = useNuxtApp();
  const locale = (nuxtApp.$i18n as any)?.locale;
  const redirectUrl = `/${locale?.value || ''}/${PUBLIC_STUDIO_REGISTER_PAGE_URL}`;
  if (
    gdsInfo.value.nation === DEFAULT_NATION &&
    userInfo.value?.personVerifyYn === Confirmation.NO
  ) {
    return await goToStudioOnStoveAccountVerificationPage(redirectUrl);
  } else if (gdsInfo.value.nation !== DEFAULT_NATION && !userInfo.value?.birthDt) {
    return await goToPolicyStudioBirthPage(redirectUrl);
  }
  const birthDayTimestamp = userInfo.value?.birthDt || Date.now();
  const ageInMiliseconds = Date.now() - birthDayTimestamp;
  if (ageInMiliseconds >= MINIMUM_AGES_ALLOWED_TO_REGISTER_IN_MILISECONDS) {
    return;
  }
  return await redirectTo(PUBLIC_STUDIO_SERVICE_UNAVAILABLE_PAGE_NAME, {
    toType: 'name',
    query: {
      errorType: ServiceUnavailable.AGE_RESTRICTION
    }
  });
});
